import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { CircleLoader } from "react-spinners";
import "./spec-template.css";
import _ from "lodash";

const SpecTemplate = () => {
  const axiosPrivate = useAxiosPrivate();
  const [sections, setSections] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [updatedSections, setUpdatedSections] = useState<any>([]);
  const [specTemplate, setSpecTemplate] = useState<any>({});
  const [modelName, setModelName] = useState<any>("");
  const [specTemplateModels, setSpecTemplateModels] = useState<any>([]);
  const [modelId, setModelId] = useState<any>("");
  const [isUpdating, setIsUpdating] = useState<any>(false);
  let isSectionUpdated: boolean = false;
  useEffect(() => {
    // getSpecConfigurations();
    getSpecTemplateModels();
    isSectionUpdated = false;
  }, []);

  const getSpecTemplateModels = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        "settings/get_all_spec_template_models"
      );
      setSpecTemplateModels(_.cloneDeep(response.data));

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const getSpecConfigurations = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("settings/get_spec_sections");
      setSections(_.cloneDeep(response.data));
      setUpdatedSections(_.cloneDeep(response.data));

      resetSpecTemplate(response.data);

      setIsLoading(false);
      return _.cloneDeep(response.data);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      return {};
    }
  };

  const resetSpecTemplate = (template: any) => {
    let sections = prepareSections(template);

    setSpecTemplate({ id: "", template_id: "", model: "", sections: sections });
    setModelName("");
  };

  const prepareSections = (template: any) => {
    let sections = _.map(template, (sec) => {
      let properties = _.map(sec.properties, (p: any) => {
        // let value: any = !_.isEmpty(p.values) ? _.first(p.values) : {};
        // return { id: p.id, value: value.id || "" };
        return { id: p.id, value: "" };
      });
      return { section_id: sec.section_id, properties: properties };
    });
    return sections;
  };

  const clearAllData = () => {
    setSections([]);
    setUpdatedSections([]);
    setSpecTemplate({});
    setModelName("");
    setModelId("");
    setIsUpdating(false);
  };

  const getPropertyValue = (section: any, property: any) => {
    let value =
      _.find(
        _.find(specTemplate.sections, {
          section_id: section.section_id,
        })?.properties,
        { id: property.id }
      )?.value || "";
    return value;
  };

  const setPropertyValue = (section: any, property: any, value: any) => {
    let sectionId = _.findIndex(specTemplate.sections, {
      section_id: section.section_id,
    });
    let propertyId = _.findIndex(specTemplate.sections[sectionId].properties, {
      id: property.id,
    });
    specTemplate.sections[sectionId].properties[propertyId].value = value;
  };

  const setPropertyName = (section: any, property: any, value: any) => {
    let sectionId = _.findIndex(specTemplate.sections, {
      section_id: section.section_id,
    });
    let propertyId = _.findIndex(specTemplate.sections[sectionId].properties, {
      id: property.id,
    });
    updatedSections[sectionId].properties[propertyId].name = value;
    // console.log(updatedSections);
  };

  const saveSpcConfiguration = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!modelName) {
      toast.error("Model name is empty!");
      return;
    }
    try {
      setIsLoading(true);
      const specSectionsResponse = await axiosPrivate.get(
        "settings/get_spec_sections"
      );
      let sections = prepareSections(specSectionsResponse.data);

      let template = {
        id: "",
        template_id: uuid(),
        model: modelName,
        sections: sections,
      };

      const response = await axiosPrivate.post(
        "settings/save_spec_template",
        template
      );

      setIsLoading(false);
      clearAllData();
      await getSpecTemplateModels();
      toast.success("Successfully saved!");
    } catch (e: any) {
      toast.error(e?.response?.data?.detail || "Failed!");
      // console.error(e);
      setIsLoading(false);
    }
  };

  const duplicateSpcConfiguration = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!specTemplate.model) {
      toast.error("Model name is empty!");
      return;
    }
    try {
      setIsLoading(true);
      let dupTemplate = _.cloneDeep(specTemplate);
      dupTemplate.id = "";
      dupTemplate.template_id = uuid();
      const response = await axiosPrivate.post(
        "settings/save_spec_template",
        dupTemplate
      );

      setIsLoading(false);
      clearAllData();
      await getSpecTemplateModels();
      toast.success("Successfully saved!");
    } catch (e: any) {
      toast.error(e?.response?.data?.detail || "Failed!");
      // console.error(e);
      setIsLoading(false);
    }
  };

  const updateSpcConfiguration = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!specTemplate.model) {
      toast.error("Model name is empty!");
      return;
    }
    try {
      setIsLoading(true);
      if (isSectionUpdated) {
        const response1 = await axiosPrivate.post(
          "settings/save_spec_sections",
          { spec_sections: updatedSections }
        );
        isSectionUpdated = false;
        setSections(_.cloneDeep(updatedSections));
      }

      const response = await axiosPrivate.post(
        "settings/update_spec_template",
        specTemplate
      );
      // resetSpecTemplate(_.cloneDeep(specTemplate));

      setIsLoading(false);
      // setIsUpdating(false);
      toast.success("Successfully saved!");
      await getSpecTemplateModels();
    } catch (e: any) {
      toast.error(e?.response?.data?.detail || "Failed!");
      // console.error(e);
      setIsLoading(false);
    }
  };

  const deleteSpcConfiguration = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!!modelId && window.confirm("Are you sure?")) {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.delete(
          `settings/delete_spec_template/${modelId}`
        );
        setIsLoading(false);
        toast.success("Successfully deleted!");
        await getSpecTemplateModels();
        clearAllData();
      } catch (error) {
        toast.error("Failed!");
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className={`mt-0 ${isLoading ? "disabled" : ""}`}>
        <div className="row m-t-10">
          <div className="col-md-4">
            <select
              value={modelId}
              className="form-select"
              aria-label="Default select example"
              onChange={async (e) => {
                setModelId(e.target.value);
                try {
                  if (!!e.target.value) {
                    setIsUpdating(true);
                    setIsLoading(true);
                    let secs = await getSpecConfigurations();
                    const response = await axiosPrivate.get(
                      "settings/get_spec_template_by_template_id/" +
                        e.target.value
                    );
                    let temp = _.cloneDeep(response.data);
                    let allSections = _.map(secs, (s) => {
                      let sec = _.find(temp.sections, {
                        section_id: s.section_id,
                      });
                      let prop = _.map(s.properties, (p) => {
                        let v = "";
                        if (!!sec) {
                          v =
                            _.find(sec?.properties, { id: p.id })?.value || "";
                        }
                        return { id: p.id, value: v };
                      });
                      return { section_id: s.section_id, properties: prop };
                    });
                    temp.sections = allSections;
                    setSpecTemplate(temp);
                    setModelName(response.data.model);
                    setIsLoading(false);
                  } else {
                    clearAllData();
                  }
                } catch (error) {
                  setIsLoading(false);
                }
              }}
            >
              <option value="">Select Model</option>
              {specTemplateModels.map((model: any) => (
                <option key={uuid()} value={model.template_id}>
                  {model.model}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            {/* <label className="form-label">Model Name</label> */}
            <input
              type="text"
              className="form-control"
              placeholder="Model Name"
              value={modelName}
              // defaultValue={specTemplate.model}
              onChange={(e) => {
                let value = e.target.value;
                specTemplate.model = value;
                setModelName(value);
              }}
            />
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            <button className="btn btn-success m-r-5" onClick={clearAllData}>
              Reset
            </button>
            <button
              className={`btn btn-success m-r-5 ${
                isUpdating ? "display-none" : "display-block"
              }`}
              onClick={saveSpcConfiguration}
            >
              Create New
            </button>
            <button
              className={`btn btn-success m-r-5 ${
                isUpdating ? "display-block" : "display-none"
              }`}
              onClick={duplicateSpcConfiguration}
            >
              Duplicate
            </button>
            <button
              className="btn btn-success m-r-5"
              onClick={updateSpcConfiguration}
              disabled={!isUpdating}
            >
              Update
            </button>
            <button
              className="btn btn-danger"
              onClick={deleteSpcConfiguration}
              disabled={!isUpdating}
            >
              Delete
            </button>
          </div>
        </div>
        <ul className="nav nav-tabs m-t-10" id="myTab" role="tablist">
          {sections.map((section: any, index: number) => (
            <li className="nav-item" role="presentation" key={uuid()}>
              <button
                onClick={() => {
                  setActiveTab(index);
                  setSections(_.cloneDeep(updatedSections));
                }}
                className={`nav-link ${activeTab == index ? "active" : ""}`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target={`#section-${index}`}
                type="button"
                role="tab"
                aria-controls={`section-${index}`}
                aria-selected="true"
              >
                <span>{section.name}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content p-t-10" id="myTabContent">
          {sections.map((section: any, index: number) => (
            <div
              key={uuid()}
              className={`tab-pane fade ${
                activeTab == index ? "show active" : ""
              }`}
              id={`section-${index}`}
              role="tabpanel"
              aria-labelledby={`section-${index}`}
            >
              {/* <div className="row">
                <div className="col-4">Section Property Name</div>
                <div className="col-8">Section Property Value</div>
              </div> */}
              <div className="property-container">
                {section.properties &&
                  section.properties.map((property: any, ind: number) => (
                    <div key={uuid()} className="m-b-5">
                      <div className="row m-b-5">
                        <div className="col-4">
                          {section.name === "DELETIONS" ? (
                            <input
                              type="text"
                              className={`form-control`}
                              defaultValue={property.name}
                              onChange={(e) => {
                                let value = e.target.value;
                                isSectionUpdated = true;
                                setPropertyName(section, property, value);
                              }}
                            />
                          ) : (
                            <strong>{property.name}</strong>
                          )}
                        </div>
                        <div className="col-8 d-flex">
                          {property.type == "dropdown" ? (
                            <select
                              className="form-select"
                              defaultValue={getPropertyValue(section, property)} //{property.type || "text"}
                              onChange={(e) => {
                                let value = e.target.value;
                                setPropertyValue(section, property, value);
                              }}
                            >
                              <option value="">Select {property.name}</option>
                              {property.values.map((val: any, vInd: number) => {
                                return (
                                  <option value={val.id} key={uuid()}>
                                    {val.value}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <input
                              type="text"
                              className={`form-control`}
                              defaultValue={getPropertyValue(section, property)}
                              onChange={(e) => {
                                let value = e.target.value;
                                setPropertyValue(section, property, value);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <CircleLoader
        loading={isLoading}
        color="#36d7b7"
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "#36d7b7",
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export default SpecTemplate;
