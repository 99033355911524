import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { CircleLoader } from "react-spinners";
// import "./spec-template-query.css";
import _ from "lodash";
import userStorage from "../../common/userStorage";
const { REACT_APP_API_BASE_URL } = process.env;
const BASE_URL = `${REACT_APP_API_BASE_URL}/api`;

const Spec = () => {
  const axiosPrivate = useAxiosPrivate();
  const [sections, setSections] = useState<any>([]);
  const [allProperties, setAllProperties] = useState<any>([]);
  const [selectedSectionProperty, setSelectedSectionProperty] = useState<any>(
    {}
  );
  const [allQueries, setAllQueries] = useState<any>({});
  const [allQueriesRaw, setAllQueriesRaw] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [updatedSections, setUpdatedSections] = useState<any>([]);
  const [specTemplate, setSpecTemplate] = useState<any>({});
  const [specTemplateBackup, setSpecTemplateBackup] = useState<any>({});
  const [modelName, setModelName] = useState<any>("");
  const [specTemplateModels, setSpecTemplateModels] = useState<any>([]);
  const [modelId, setModelId] = useState<any>("");
  const [isUpdating, setIsUpdating] = useState<any>(false);
  const [sectionModalShow, setSectionModalShow] = useState(false);

  const handleSectionModalClose = () => setSectionModalShow(false);
  const handleSectionModalShow = () => setSectionModalShow(true);

  useEffect(() => {
    // getSpecConfigurations();
    getSpecTemplateModels();
  }, []);

  const getSpecTemplateModels = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        "settings/get_all_spec_template_models"
      );
      setSpecTemplateModels(_.cloneDeep(response.data));

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const getSpecConfigurations = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("settings/get_spec_sections");
      setSections(_.cloneDeep(response.data));
      setUpdatedSections(_.cloneDeep(response.data));

      setAllProperties(_.cloneDeep(response.data));

      resetSpecTemplate(response.data);

      setIsLoading(false);

      return response.data;
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      return null;
    }
  };

  const resetSpecTemplate = (template: any) => {
    let sections = prepareSections(template);

    setSpecTemplate({ id: "", template_id: "", model: "", sections: sections });
    setModelName("");
  };

  const prepareSections = (template: any) => {
    let sections = _.map(template, (sec) => {
      let properties = _.map(sec.properties, (p: any) => {
        // let value: any = !_.isEmpty(p.values) ? _.first(p.values) : {};
        // return { id: p.id, value: value.id || "" };
        return { id: p.id, value: "" };
      });
      return { section_id: sec.section_id, properties: properties };
    });
    return sections;
  };

  const clearAllData = () => {
    setSections([]);
    setUpdatedSections([]);
    setSpecTemplate({});
    setSpecTemplateBackup({});
    setModelName("");
    setModelId("");
    setIsUpdating(false);
  };

  const getPropertyValue = (section: any, property: any, temp: any = null) => {
    let specTempSecs = !!temp ? temp.sections : specTemplate.sections;
    let value =
      _.find(
        _.find(specTempSecs, {
          section_id: section.section_id,
        })?.properties,
        { id: property.id }
      )?.value || "";
    return value;
  };

  const setPropertyName = (section: any, property: any, value: any) => {
    let sectionId = _.findIndex(specTemplate.sections, {
      section_id: section.section_id,
    });
    let propertyId = _.findIndex(specTemplate.sections[sectionId].properties, {
      id: property.id,
    });
    sections[sectionId].properties[propertyId].name = value;
    updatedSections[sectionId].properties[propertyId].name = value;
    // console.log(updatedSections);
  };

  const setPropertyValue = (section: any, property: any, value: any) => {
    let sectionId = _.findIndex(specTemplate.sections, {
      section_id: section.section_id,
    });
    let propertyId = _.findIndex(specTemplate.sections[sectionId].properties, {
      id: property.id,
    });
    specTemplate.sections[sectionId].properties[propertyId].value = value;

    let queries = _.filter(allQueriesRaw, (rq) => {
      let cons = _.filter(rq.conditions, (c) => {
        let aCons = _.filter(c.and_conditions, {
          section_id: section.section_id,
          property_id: property.id,
        });
        return !_.isEmpty(aCons);
      });
      return !_.isEmpty(cons);
    });

    let simplifiedQueries = _.map(queries, (rq) => {
      let conditions = _.map(rq.conditions, (c) => {
        let and_conditions = _.map(c.and_conditions, (ac) => {
          let sectionId = _.findIndex(specTemplate.sections, {
            section_id: ac.section_id,
          });
          let propertyId = _.findIndex(
            specTemplate.sections[sectionId].properties,
            {
              id: ac.property_id,
            }
          );
          return eval(
            `'${specTemplate.sections[sectionId].properties[propertyId].value}' ${ac.comparator} '${ac.property_value}'`
          );
        });
        return {
          andConditionsEval: and_conditions.every((v) => v === true),
          value: c.value,
        };
      });

      return {
        property_id: rq.property_id,
        section_id: rq.section_id,
        conditions: conditions,
      };
    });
    // console.log(simplifiedQueries);
    for (let i = 0; i < simplifiedQueries.length; i++) {
      for (let j = 0; j < simplifiedQueries[i].conditions.length; j++) {
        if (simplifiedQueries[i].conditions[j].andConditionsEval) {
          let sId = _.findIndex(specTemplate.sections, {
            section_id: simplifiedQueries[i].section_id,
          });
          let pId = _.findIndex(specTemplate.sections[sId].properties, {
            id: simplifiedQueries[i].property_id,
          });
          specTemplate.sections[sId].properties[pId].value =
            simplifiedQueries[i].conditions[j].value;
          break;
        }
      }
    }
    setSpecTemplate(_.cloneDeep(specTemplate));
  };

  const getUpdatedPropertyValue = (
    section: any,
    property: any,
    value: any,
    temp: any,
    rewQur: any
  ) => {
    let sectionId = _.findIndex(temp.sections, {
      section_id: section.section_id,
    });
    let propertyId = _.findIndex(temp.sections[sectionId].properties, {
      id: property.id,
    });
    if (propertyId == -1) {
      return temp;
    }
    temp.sections[sectionId].properties[propertyId].value = value;

    let queries = _.filter(rewQur, (rq) => {
      let cons = _.filter(rq.conditions, (c) => {
        let aCons = _.filter(c.and_conditions, {
          section_id: section.section_id,
          property_id: property.id,
        });
        return !_.isEmpty(aCons);
      });
      return !_.isEmpty(cons);
    });

    let simplifiedQueries = _.map(queries, (rq) => {
      let conditions = _.map(rq.conditions, (c) => {
        let and_conditions = _.map(c.and_conditions, (ac) => {
          let sectionId = _.findIndex(temp.sections, {
            section_id: ac.section_id,
          });
          let propertyId = _.findIndex(temp.sections[sectionId].properties, {
            id: ac.property_id,
          });
          return eval(
            `'${temp.sections[sectionId].properties[propertyId].value}' ${ac.comparator} '${ac.property_value}'`
          );
        });
        return {
          andConditionsEval: and_conditions.every((v) => v === true),
          value: c.value,
        };
      });

      return {
        property_id: rq.property_id,
        section_id: rq.section_id,
        conditions: conditions,
      };
    });
    // console.log(simplifiedQueries);
    for (let i = 0; i < simplifiedQueries.length; i++) {
      for (let j = 0; j < simplifiedQueries[i].conditions.length; j++) {
        if (simplifiedQueries[i].conditions[j].andConditionsEval) {
          let sId = _.findIndex(temp.sections, {
            section_id: simplifiedQueries[i].section_id,
          });
          let pId = _.findIndex(temp.sections[sId].properties, {
            id: simplifiedQueries[i].property_id,
          });
          temp.sections[sId].properties[pId].value =
            simplifiedQueries[i].conditions[j].value;
          break;
        }
      }
    }
    // setSpecTemplate(_.cloneDeep(specTemplate));
    return temp;
  };

  const get_spec_template_queries_by_template_id = async (
    template_id: any,
    specTemp: any,
    secs: any,
    temps: any
  ) => {
    try {
      setIsUpdating(true);
      setIsLoading(true);

      const response = await axiosPrivate.get(
        "settings/get_spec_template_queries_by_template_id/" + template_id
      );
      setAllQueriesRaw(_.cloneDeep(response.data.queries));
      let queries: any = {};
      _.forEach(response.data.queries, (query) => {
        let conditions = _.map(query.conditions, (con) => {
          let and_conditions = _.map(con.and_conditions, (aCon) => {
            let sectionId = _.findIndex(specTemp.sections, {
              section_id: aCon.section_id,
            });
            let propertyId = _.findIndex(
              specTemp.sections[sectionId].properties,
              {
                id: aCon.property_id,
              }
            );
            return {
              comparator: aCon.comparator,
              property_id: aCon.property_id,
              property_index: propertyId,
              property_value: aCon.property_value,
              section_id: aCon.section_id,
              section_index: sectionId,
            };
          });
          return {
            and_conditions: and_conditions,
            condition_type: con.condition_type,
            value: con.value,
          };
        });
        queries[`${query.section_id}-${query.property_id}`] = {
          property_id: query.property_id,
          section_id: query.section_id,
          conditions: conditions,
        };
      });
      setAllQueries(queries);
      let templates = _.cloneDeep(temps);
      _.forEach(secs, (section) => {
        _.forEach(section?.properties, (property) => {
          let value = getPropertyValue(section, property, templates);
          if (!value) {
            let a = value;
          }
          templates = getUpdatedPropertyValue(
            section,
            property,
            value,
            templates,
            _.cloneDeep(response.data.queries)
          );
        });
      });
      setTimeout(() => {
        setSpecTemplate(_.cloneDeep(templates));
      }, 0);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const downloadPdf = async () => {
    console.log(sections);
    console.log(specTemplate);

    let secs = _.map(specTemplate.sections, (sec, sIn) => {
      let section = _.find(sections, { section_id: sec.section_id });
      let properties = _.map(sec.properties, (prop, pInd) => {
        let property = _.find(section.properties, { id: prop.id });
        let value = prop.value;
        if (property.type === "dropdown") {
          let valueObj = _.find(property.values, { id: value });
          value = valueObj?.value;
        }
        return {
          name: property?.name,
          value: value,
        };
      });
      return {
        name: section?.name,
        column: section?.column,
        row: section?.row,
        properties: properties,
      };
    });

    let allTemp = {
      template_id: specTemplate?.template_id,
      model: specTemplate?.model,
      sections: secs,
    };
    const userInfo = userStorage.getAuthInfo();
    fetch(`${BASE_URL}/settings/download_spec_pdf`, {
      method: "POST",
      body: JSON.stringify(allTemp),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.download = `${allTemp.model}-${new Date().toLocaleString()}.pdf`;
        a.href = href;
        a.click();
        a.href = "";
      })
      .catch((err) => console.error(err));

    // const response = await axiosPrivate.post("settings/download_spec_pdf", {
    //   responseType: "blob",
    // });
    // // var bytes = [];
    // // let str = response.data;
    // // for (var i = 0; i < str.length; ++i) {
    // //   bytes.push(str.charCodeAt(i));
    // // }

    // let utf8Encode = new TextEncoder();

    // const d: any = utf8Encode.encode(response.data);

    // const file = new Blob([response.data], {
    //   type: "application/pdf",
    // });
    // var url = window.URL.createObjectURL(file);
    // var a = document.createElement("a");
    // a.href = url;
    // a.download = "spec2.pdf";
    // document.body.appendChild(a); // append the element to the dom
    // a.click();
    // a.remove(); // afterwards, remove the element
    // console.log(allTemp);
  };

  return (
    <>
      <div className={`mt-0 ${isLoading ? "disabled" : ""}`}>
        <div className="row m-t-10">
          <div className="col-md-4">
            <select
              value={modelId}
              className="form-select"
              aria-label="Default select example"
              onChange={async (e) => {
                setModelId(e.target.value);
                try {
                  if (!!e.target.value) {
                    setIsUpdating(true);
                    setIsLoading(true);
                    let secs = await getSpecConfigurations();
                    const response = await axiosPrivate.get(
                      "settings/get_spec_template_by_template_id/" +
                        e.target.value
                    );
                    let temp = _.cloneDeep(response.data);
                    let updatedSections = _.map(secs, (s) => {
                      let sec = _.find(temp.sections, {
                        section_id: s.section_id,
                      });
                      let prop = _.map(s.properties, (p) => {
                        let v = "";
                        if (!!sec) {
                          v =
                            _.find(sec?.properties, { id: p.id })?.value || "";
                        }
                        return { id: p.id, value: v };
                      });
                      return { section_id: s.section_id, properties: prop };
                    });
                    temp.sections = updatedSections;
                    setSpecTemplate(temp);
                    setSpecTemplateBackup(_.cloneDeep(temp));
                    setModelName(response.data.model);
                    await get_spec_template_queries_by_template_id(
                      e.target.value,
                      response.data,
                      secs,
                      _.cloneDeep(response.data)
                    );
                    setIsLoading(false);
                  } else {
                    clearAllData();
                  }
                } catch (error) {
                  setIsLoading(false);
                }
              }}
            >
              <option value="">Select Model</option>
              {specTemplateModels.map((model: any) => (
                <option key={uuid()} value={model.template_id}>
                  {model.model}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              placeholder="Model Name"
              value={modelName}
              onChange={(e) => {
                let value = e.target.value;
                specTemplate.model = value;
                setModelName(value);
              }}
            />
          </div> */}
          <div className="col-md-4 d-flex justify-content-start">
            {/* <button className="btn btn-success m-r-5" onClick={clearAllData}>
              Reset
            </button>
            <button
              className="btn btn-success m-r-5"
              onClick={saveSpcConfiguration}
              disabled={isUpdating}
            >
              Create New
            </button> */}
            {/* <button
              className="btn btn-success m-r-5"
              onClick={updateSpcConfiguration}
              // disabled={!isUpdating}
            >
              Update
            </button> */}
            <button className="btn btn-success" onClick={downloadPdf}>
              Download
            </button>
          </div>
        </div>
        <ul className="nav nav-tabs m-t-10" id="myTab" role="tablist">
          {sections.map((section: any, index: number) => (
            <li className="nav-item" role="presentation" key={uuid()}>
              <button
                onClick={() => {
                  setActiveTab(index);
                  setSections(_.cloneDeep(updatedSections));
                }}
                className={`nav-link ${activeTab == index ? "active" : ""}`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target={`#section-${index}`}
                type="button"
                role="tab"
                aria-controls={`section-${index}`}
                aria-selected="true"
              >
                <span>{section.name}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content p-t-10" id="myTabContent">
          {sections.map((section: any, index: number) => (
            <div
              key={uuid()}
              className={`tab-pane fade ${
                activeTab == index ? "show active" : ""
              }`}
              id={`section-${index}`}
              role="tabpanel"
              aria-labelledby={`section-${index}`}
            >
              {/* <div className="row">
                <div className="col-4">Section Property Name</div>
                <div className="col-8">Section Property Value</div>
              </div> */}
              <div className="property-container">
                {section.properties &&
                  section.properties.map((property: any, ind: number) => (
                    <div key={uuid()} className="m-b-5">
                      <div className="row m-b-5">
                        <div className="col-3">
                          {section.name === "DELETIONS" ? (
                            <input
                              type="text"
                              className={`form-control`}
                              defaultValue={property.name}
                              onChange={(e) => {
                                let value = e.target.value;
                                setPropertyName(section, property, value);
                              }}
                            />
                          ) : (
                            <strong>{property.name}</strong>
                          )}
                        </div>
                        <div className="col-9 d-flex">
                          {/* <button
                            className="btn btn-link"
                            onClick={() => {
                              setSelectedSectionProperty({
                                section_id: section.section_id,
                                section_name: section.name,
                                section_index: index,
                                property_id: property.id,
                                property_name: property.name,
                                property_index: ind,
                              });
                              handleSectionModalShow();
                            }}
                          >
                            Query
                          </button> */}
                          {property.type == "dropdown" ? (
                            <select
                              className="form-select"
                              defaultValue={getPropertyValue(section, property)} //{property.type || "text"}
                              onChange={(e) => {
                                let value = e.target.value;
                                setPropertyValue(section, property, value);
                              }}
                            >
                              <option value="">Select {property.name}</option>
                              {property.values.map((val: any, vInd: number) => {
                                return (
                                  <option value={val.id} key={uuid()}>
                                    {val.value}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <input
                              type="text"
                              className={`form-control`}
                              defaultValue={getPropertyValue(section, property)}
                              onBlur={(e) => {
                                let value = e.target.value;
                                setPropertyValue(section, property, value);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <CircleLoader
        loading={isLoading}
        color="#36d7b7"
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "#36d7b7",
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export default Spec;
