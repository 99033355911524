import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { CircleLoader } from "react-spinners";
import "./spec-template-query.css";
import _ from "lodash";

interface LooseObject {
  [key: string]: any;
}

const SpecTemplateQuery = () => {
  const axiosPrivate = useAxiosPrivate();
  const [sections, setSections] = useState<any>([]);
  const [allProperties, setAllProperties] = useState<any>([]);
  const [selectedSectionProperty, setSelectedSectionProperty] = useState<any>(
    {}
  );
  const [allQueries, setAllQueries] = useState<any>({});
  const [allQueriesRaw, setAllQueriesRaw] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [updatedSections, setUpdatedSections] = useState<any>([]);
  const [specTemplate, setSpecTemplate] = useState<any>({});
  const [specTemplateBackup, setSpecTemplateBackup] = useState<any>({});
  const [modelName, setModelName] = useState<any>("");
  const [specTemplateModels, setSpecTemplateModels] = useState<any>([]);
  const [modelId, setModelId] = useState<any>("");
  const [isUpdating, setIsUpdating] = useState<any>(false);
  const [sectionModalShow, setSectionModalShow] = useState(false);

  const handleSectionModalClose = () => setSectionModalShow(false);
  const handleSectionModalShow = () => setSectionModalShow(true);

  useEffect(() => {
    // getSpecConfigurations();
    getSpecTemplateModels();
  }, []);

  const getSpecTemplateModels = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        "settings/get_all_spec_template_models"
      );
      setSpecTemplateModels(_.cloneDeep(response.data));

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const getSpecConfigurations = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("settings/get_spec_sections");
      setSections(_.cloneDeep(response.data));
      setUpdatedSections(_.cloneDeep(response.data));

      setAllProperties(_.cloneDeep(response.data));

      resetSpecTemplate(response.data);

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const resetSpecTemplate = (template: any) => {
    let sections = prepareSections(template);

    setSpecTemplate({ id: "", template_id: "", model: "", sections: sections });
    setModelName("");
  };

  const prepareSections = (template: any) => {
    let sections = _.map(template, (sec) => {
      let properties = _.map(sec.properties, (p: any) => {
        let value: any = !_.isEmpty(p.values) ? _.first(p.values) : {};
        return { id: p.id, value: value.id || "" };
      });
      return { section_id: sec.section_id, properties: properties };
    });
    return sections;
  };

  const clearAllData = () => {
    setSections([]);
    setUpdatedSections([]);
    setSpecTemplate({});
    setSpecTemplateBackup({});
    setModelName("");
    setModelId("");
    setIsUpdating(false);
  };

  const getPropertyValue = (section: any, property: any) => {
    let value =
      _.find(
        _.find(specTemplate.sections, {
          section_id: section.section_id,
        })?.properties,
        { id: property.id }
      )?.value || "";
    return value;
  };

  const setPropertyValue = (section: any, property: any, value: any) => {
    let sectionId = _.findIndex(specTemplate.sections, {
      section_id: section.section_id,
    });
    let propertyId = _.findIndex(specTemplate.sections[sectionId].properties, {
      id: property.id,
    });
    specTemplate.sections[sectionId].properties[propertyId].value = value;

    let queries = _.filter(allQueriesRaw, (rq) => {
      let cons = _.filter(rq.conditions, (c) => {
        let aCons = _.filter(c.and_conditions, {
          section_id: section.section_id,
          property_id: property.id,
        });
        return !_.isEmpty(aCons);
      });
      return !_.isEmpty(cons);
    });

    let simplifiedQueries = _.map(queries, (rq) => {
      let conditions = _.map(rq.conditions, (c) => {
        let and_conditions = _.map(c.and_conditions, (ac) => {
          let sectionId = _.findIndex(specTemplate.sections, {
            section_id: ac.section_id,
          });
          let propertyId = _.findIndex(
            specTemplate.sections[sectionId].properties,
            {
              id: ac.property_id,
            }
          );
          return eval(
            `'${specTemplate.sections[sectionId].properties[propertyId].value}' ${ac.comparator} '${ac.property_value}'`
          );
        });
        return {
          andConditionsEval: and_conditions.every((v) => v === true),
          value: c.value,
        };
      });

      return {
        property_id: rq.property_id,
        section_id: rq.section_id,
        conditions: conditions,
      };
    });
    // console.log(simplifiedQueries);
    for (let i = 0; i < simplifiedQueries.length; i++) {
      for (let j = 0; j < simplifiedQueries[i].conditions.length; j++) {
        if (simplifiedQueries[i].conditions[j].andConditionsEval) {
          let sId = _.findIndex(specTemplate.sections, {
            section_id: simplifiedQueries[i].section_id,
          });
          let pId = _.findIndex(specTemplate.sections[sId].properties, {
            id: simplifiedQueries[i].property_id,
          });
          specTemplate.sections[sId].properties[pId].value =
            simplifiedQueries[i].conditions[j].value;
          break;
        }
      }
    }
    setSpecTemplate(_.cloneDeep(specTemplate));
  };

  const saveSpcConfiguration = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!modelName) {
      toast.error("Model name is empty!");
      return;
    }
    try {
      setIsLoading(true);
      const specSectionsResponse = await axiosPrivate.get(
        "settings/get_spec_sections"
      );
      let sections = prepareSections(specSectionsResponse.data);

      let template = {
        id: "",
        template_id: uuid(),
        model: modelName,
        sections: sections,
      };

      const response = await axiosPrivate.post(
        "settings/save_spec_template",
        template
      );

      setIsLoading(false);
      clearAllData();
      await getSpecTemplateModels();
      toast.success("Successfully saved!");
    } catch (e: any) {
      toast.error(e?.response?.data?.detail || "Failed!");
      // console.error(e);
      setIsLoading(false);
    }
  };

  const updateSpcConfiguration = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!specTemplate.model) {
      toast.error("Model name is empty!");
      return;
    }
    try {
      setIsLoading(true);
      const response = await axiosPrivate.post(
        "settings/update_spec_template",
        specTemplate
      );
      // resetSpecTemplate(_.cloneDeep(specTemplate));

      setIsLoading(false);
      // setIsUpdating(false);
      toast.success("Successfully saved!");
      await getSpecTemplateModels();
    } catch (e: any) {
      toast.error(e?.response?.data?.detail || "Failed!");
      // console.error(e);
      setIsLoading(false);
    }
  };

  const deleteSpcConfiguration = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!!modelId && window.confirm("Are you sure?")) {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.delete(
          `settings/delete_spec_template/${modelId}`
        );
        setIsLoading(false);
        toast.success("Successfully deleted!");
        await getSpecTemplateModels();
        clearAllData();
      } catch (error) {
        toast.error("Failed!");
        setIsLoading(false);
      }
    }
  };

  // const getProperties = (secId: any) => {
  //   _.find(sections, { section_id: secId })?.properties || [];
  // };

  const saveQuery = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    let queries = [];
    for (let key in allQueries) {
      queries.push(allQueries[key]);
    }
    let formatedQueries = _.map(queries, (query) => {
      let conditions = _.map(query.conditions, (con) => {
        let and_conditions = _.map(con.and_conditions, (aCon) => {
          return {
            comparator: aCon.comparator || "==",
            property_id: aCon.property_id,
            property_value: aCon.property_value,
            section_id: aCon.section_id,
          };
        });
        return {
          and_conditions: and_conditions,
          condition_type: con.condition_type,
          value: con.value,
        };
      });
      if (_.isEmpty(conditions)) {
        return null;
      }
      return {
        property_id: query.property_id,
        section_id: query.section_id,
        conditions: conditions,
      };
    });
    let templateQueris = {
      template_id: specTemplate.template_id,
      queries: _.filter(formatedQueries, (q) => !!q),
    };
    try {
      setIsLoading(true);
      const response = await axiosPrivate.post(
        `settings/save_spec_template_queries`,
        templateQueris
      );
      await get_spec_template_queries_by_template_id(
        specTemplate.template_id,
        specTemplate
      );
      handleSectionModalClose();
      setIsLoading(false);
      toast.success("Successfully saved!");
    } catch (error) {
      toast.error("Failed!");
      setIsLoading(false);
    }
  };

  const get_spec_template_queries_by_template_id = async (
    template_id: any,
    specTemp: any
  ) => {
    try {
      setIsUpdating(true);
      setIsLoading(true);

      const response = await axiosPrivate.get(
        "settings/get_spec_template_queries_by_template_id/" + template_id
      );
      setAllQueriesRaw(_.cloneDeep(response.data.queries));
      let queries: any = {};
      _.forEach(response.data.queries, (query) => {
        let conditions = _.map(query.conditions, (con) => {
          let and_conditions = _.map(con.and_conditions, (aCon) => {
            let sectionId = _.findIndex(specTemp.sections, {
              section_id: aCon.section_id,
            });
            let propertyId = _.findIndex(
              specTemp.sections[sectionId].properties,
              {
                id: aCon.property_id,
              }
            );
            return {
              comparator: aCon.comparator,
              property_id: aCon.property_id,
              property_index: propertyId,
              property_value: aCon.property_value,
              section_id: aCon.section_id,
              section_index: sectionId,
            };
          });
          return {
            and_conditions: and_conditions,
            condition_type: con.condition_type,
            value: con.value,
          };
        });
        queries[`${query.section_id}-${query.property_id}`] = {
          property_id: query.property_id,
          section_id: query.section_id,
          conditions: conditions,
        };
      });
      setAllQueries(queries);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={`mt-0 ${isLoading ? "disabled" : ""}`}>
        <div className="row m-t-10">
          <div className="col-md-4">
            <select
              value={modelId}
              className="form-select"
              aria-label="Default select example"
              onChange={async (e) => {
                setModelId(e.target.value);
                try {
                  if (!!e.target.value) {
                    setIsUpdating(true);
                    setIsLoading(true);
                    await getSpecConfigurations();
                    const response = await axiosPrivate.get(
                      "settings/get_spec_template_by_template_id/" +
                        e.target.value
                    );
                    setSpecTemplate(_.cloneDeep(response.data));
                    setSpecTemplateBackup(_.cloneDeep(response.data));
                    setModelName(response.data.model);
                    await get_spec_template_queries_by_template_id(
                      e.target.value,
                      response.data
                    );
                    setIsLoading(false);
                  } else {
                    clearAllData();
                  }
                } catch (error) {
                  setIsLoading(false);
                }
              }}
            >
              <option value="">Select Model</option>
              {specTemplateModels.map((model: any) => (
                <option key={uuid()} value={model.template_id}>
                  {model.model}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              placeholder="Model Name"
              value={modelName}
              onChange={(e) => {
                let value = e.target.value;
                specTemplate.model = value;
                setModelName(value);
              }}
            />
          </div> */}
          <div className="col-md-4 d-flex justify-content-end">
            {/* <button className="btn btn-success m-r-5" onClick={clearAllData}>
              Reset
            </button>
            <button
              className="btn btn-success m-r-5"
              onClick={saveSpcConfiguration}
              disabled={isUpdating}
            >
              Create New
            </button> */}
            {/* <button
              className="btn btn-success m-r-5"
              onClick={updateSpcConfiguration}
              // disabled={!isUpdating}
            >
              Update
            </button> */}
            {/* <button
              className="btn btn-danger"
              onClick={deleteSpcConfiguration}
              disabled={!isUpdating}
            >
              Delete
            </button> */}
          </div>
        </div>
        <ul className="nav nav-tabs m-t-10" id="myTab" role="tablist">
          {sections.map((section: any, index: number) => (
            <li className="nav-item" role="presentation" key={uuid()}>
              <button
                onClick={() => {
                  setActiveTab(index);
                  setSections(_.cloneDeep(updatedSections));
                }}
                className={`nav-link ${activeTab == index ? "active" : ""}`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target={`#section-${index}`}
                type="button"
                role="tab"
                aria-controls={`section-${index}`}
                aria-selected="true"
              >
                <span>{section.name}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content p-t-10" id="myTabContent">
          {sections.map((section: any, index: number) => (
            <div
              key={uuid()}
              className={`tab-pane fade ${
                activeTab == index ? "show active" : ""
              }`}
              id={`section-${index}`}
              role="tabpanel"
              aria-labelledby={`section-${index}`}
            >
              {/* <div className="row">
                <div className="col-4">Section Property Name</div>
                <div className="col-8">Section Property Value</div>
              </div> */}
              <div className="property-container">
                {section.properties &&
                  section.properties.map((property: any, ind: number) => (
                    <div key={uuid()} className="m-b-5">
                      <div className="row m-b-5">
                        <div className="col-3">
                          <strong>{property.name}</strong>
                        </div>
                        <div className="col-9 d-flex">
                          <button
                            className="btn btn-link"
                            onClick={() => {
                              setSelectedSectionProperty({
                                section_id: section.section_id,
                                section_name: section.name,
                                section_index: index,
                                property_id: property.id,
                                property_name: property.name,
                                property_index: ind,
                              });
                              handleSectionModalShow();
                            }}
                          >
                            Query
                          </button>
                          {property.type == "dropdown" ? (
                            <select
                              className="form-select"
                              defaultValue={getPropertyValue(section, property)} //{property.type || "text"}
                              onChange={(e) => {
                                let value = e.target.value;
                                setPropertyValue(section, property, value);
                              }}
                            >
                              {property.values.map((val: any, vInd: number) => {
                                return (
                                  <option value={val.id} key={uuid()}>
                                    {val.value}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <input
                              type="text"
                              className={`form-control`}
                              defaultValue={getPropertyValue(section, property)}
                              onBlur={(e) => {
                                let value = e.target.value;
                                setPropertyValue(section, property, value);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{ display: sectionModalShow ? "block" : "none" }}
        className="modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-eidth-98w" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add/Update query for section:{" "}
                    {selectedSectionProperty?.section_name} property:{" "}
                    {selectedSectionProperty?.property_name}
                  </h5>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleSectionModalClose}
                  ></button>
                </div>
              </div>
            </div>
            <div className="modal-body">
              {_.has(
                allQueries,
                `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
              ) &&
                allQueries[
                  `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                ].conditions.map((con: any, conInd: number) => (
                  <div key={uuid()}>
                    <div className="row">
                      <div className="col-md-6">
                        <select
                          className="form-select"
                          defaultValue={con.condition_type}
                          onChange={(e) => {
                            let value = e.target.value;
                            let queries = _.cloneDeep(allQueries);
                            queries[
                              `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                            ].conditions[conInd].condition_type = value;

                            setAllQueries(_.cloneDeep(queries));
                          }}
                        >
                          <option value="if">If</option>
                          {/* <option value="elseif">Else-If</option>
                      <option value="else">Else</option> */}
                        </select>
                      </div>
                      <div className="col-md-2">
                        <button
                          className="btn btn-sm"
                          onClick={() => {
                            let queries = _.cloneDeep(allQueries);
                            queries[
                              `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                            ].conditions.splice(conInd, 1);
                            setAllQueries(_.cloneDeep(queries));
                          }}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                    {con?.and_conditions?.map(
                      (andCon: any, andConId: number) => (
                        <div key={uuid()}>
                          <div className="row m-t-5">
                            <div className="col-md-3">
                              <select
                                className="form-select"
                                defaultValue={andCon.section_id}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  let queries = _.cloneDeep(allQueries);
                                  queries[
                                    `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                  ].conditions[conInd].and_conditions[
                                    andConId
                                  ].section_id = value;
                                  let dataInd = parseInt(
                                    e.target[
                                      e.target.selectedIndex
                                    ].getAttribute("data-index") || ""
                                  );

                                  queries[
                                    `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                  ].conditions[conInd].and_conditions[
                                    andConId
                                  ].section_index = isNaN(dataInd)
                                    ? null
                                    : dataInd;

                                  queries[
                                    `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                  ].conditions[conInd].and_conditions[
                                    andConId
                                  ].property_id = "";

                                  queries[
                                    `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                  ].conditions[conInd].and_conditions[
                                    andConId
                                  ].property_index = null;

                                  queries[
                                    `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                  ].conditions[conInd].and_conditions[
                                    andConId
                                  ].property_value = "";

                                  setAllQueries(_.cloneDeep(queries));
                                }}
                              >
                                <option value="" data-index="">
                                  Select Section
                                </option>
                                {sections?.map(
                                  (section: any, sectionInd: number) => (
                                    <option
                                      key={uuid()}
                                      value={section.section_id}
                                      data-index={sectionInd}
                                    >
                                      {section.name}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                            <div className="col-md-3">
                              <select
                                className="form-select"
                                defaultValue={andCon.property_id}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  let queries = _.cloneDeep(allQueries);
                                  queries[
                                    `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                  ].conditions[conInd].and_conditions[
                                    andConId
                                  ].property_id = value;

                                  queries[
                                    `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                  ].conditions[conInd].and_conditions[
                                    andConId
                                  ].property_value = "";

                                  let dataInd = parseInt(
                                    e.target[
                                      e.target.selectedIndex
                                    ].getAttribute("data-index") || ""
                                  );

                                  queries[
                                    `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                  ].conditions[conInd].and_conditions[
                                    andConId
                                  ].property_index = isNaN(dataInd)
                                    ? null
                                    : dataInd;

                                  setAllQueries(_.cloneDeep(queries));
                                }}
                              >
                                <option value="" data-index="">
                                  Select Property
                                </option>
                                {allQueries[
                                  `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                ]?.conditions[conInd]?.and_conditions[andConId]
                                  .section_index != null &&
                                  sections[
                                    allQueries[
                                      `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                    ]?.conditions[conInd]?.and_conditions[
                                      andConId
                                    ].section_index
                                  ]?.properties?.map(
                                    (prop: any, propInd: number) => (
                                      <option
                                        value={prop.id}
                                        data-index={propInd}
                                        key={uuid()}
                                      >
                                        {prop.name}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                            <div className="col-md-2">
                              <select
                                className="form-select"
                                defaultValue={andCon.comparator}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  let queries = _.cloneDeep(allQueries);
                                  queries[
                                    `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                  ].conditions[conInd].and_conditions[
                                    andConId
                                  ].comparator = value;

                                  setAllQueries(_.cloneDeep(queries));
                                }}
                              >
                                <option value="==">Equals</option>
                                <option value="!=">Not Equals</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              {allQueries[
                                `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                              ]?.conditions[conInd]?.and_conditions[andConId]
                                .section_index != null &&
                                allQueries[
                                  `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                ]?.conditions[conInd]?.and_conditions[andConId]
                                  .property_index != null && (
                                  <>
                                    {sections[
                                      allQueries[
                                        `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                      ]?.conditions[conInd]?.and_conditions[
                                        andConId
                                      ].section_index
                                    ]?.properties[
                                      allQueries[
                                        `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                      ]?.conditions[conInd]?.and_conditions[
                                        andConId
                                      ].property_index
                                    ]?.type == "dropdown" ? (
                                      <select
                                        className="form-select"
                                        defaultValue={andCon.property_value}
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          let queries = _.cloneDeep(allQueries);
                                          queries[
                                            `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                          ].conditions[conInd].and_conditions[
                                            andConId
                                          ].property_value = value;

                                          setAllQueries(_.cloneDeep(queries));
                                        }}
                                      >
                                        <option value="" data-index="">
                                          Select Value
                                        </option>
                                        {allQueries[
                                          `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                        ]?.conditions[conInd]?.and_conditions[
                                          andConId
                                        ].section_index != null &&
                                          allQueries[
                                            `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                          ]?.conditions[conInd]?.and_conditions[
                                            andConId
                                          ].property_index != null &&
                                          sections[
                                            allQueries[
                                              `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                            ]?.conditions[conInd]
                                              ?.and_conditions[andConId]
                                              .section_index
                                          ]?.properties[
                                            allQueries[
                                              `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                            ]?.conditions[conInd]
                                              ?.and_conditions[andConId]
                                              .property_index
                                          ]?.values?.map(
                                            (value: any, valueInd: number) => (
                                              <option
                                                value={value.id}
                                                key={uuid()}
                                              >
                                                {value.value}
                                              </option>
                                            )
                                          )}
                                      </select>
                                    ) : (
                                      <input
                                        type="text"
                                        className={`form-control`}
                                        defaultValue={andCon.property_value}
                                        onBlur={(e) => {
                                          let value = e.target.value;
                                          let queries = _.cloneDeep(allQueries);
                                          queries[
                                            `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                          ].conditions[conInd].and_conditions[
                                            andConId
                                          ].property_value = value;

                                          setAllQueries(_.cloneDeep(queries));
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                            </div>
                            <div className="col-md-1">
                              <button
                                className="btn btn-sm"
                                onClick={() => {
                                  let queries = _.cloneDeep(allQueries);
                                  queries[
                                    `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                                  ].conditions[conInd].and_conditions.splice(
                                    andConId,
                                    1
                                  );
                                  setAllQueries(_.cloneDeep(queries));
                                }}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-end">
                        <button
                          className="btn btn-link"
                          onClick={() => {
                            let queries = _.cloneDeep(allQueries);
                            let propertyName = `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`;
                            queries[propertyName].conditions[
                              conInd
                            ].and_conditions.push({
                              section_id: "",
                              section_index: null,
                              property_id: "",
                              property_index: null,
                              property_value: "",
                            });
                            setAllQueries(_.cloneDeep(queries));
                          }}
                        >
                          Add Property
                        </button>
                      </div>
                    </div>
                    <div className="row m-b-5">
                      <div className="col-2">Then</div>
                      <div className="col-4">
                        {sections[selectedSectionProperty.section_index]
                          ?.properties[selectedSectionProperty.property_index]
                          ?.type == "dropdown" ? (
                          <select
                            className="form-select"
                            defaultValue={con.value}
                            onChange={(e) => {
                              let value = e.target.value;
                              let queries = _.cloneDeep(allQueries);
                              queries[
                                `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                              ].conditions[conInd].value = value;

                              setAllQueries(_.cloneDeep(queries));
                            }}
                          >
                            <option value="" data-index="">
                              Select Value
                            </option>
                            {sections[
                              selectedSectionProperty.section_index
                            ]?.properties[
                              selectedSectionProperty.property_index
                            ]?.values?.map((value: any, valueInd: number) => (
                              <option value={value.id} key={uuid()}>
                                {value.value}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type="text"
                            className={`form-control`}
                            defaultValue={con.value}
                            onBlur={(e) => {
                              let value = e.target.value;
                              let queries = _.cloneDeep(allQueries);
                              queries[
                                `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`
                              ].conditions[conInd].value = value;

                              setAllQueries(_.cloneDeep(queries));
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              <button
                className="btn btn-link"
                onClick={() => {
                  let queries: LooseObject = _.cloneDeep(allQueries);
                  let propertyName = `${selectedSectionProperty.section_id}-${selectedSectionProperty.property_id}`;
                  if (_.has(queries, propertyName)) {
                    queries[propertyName].conditions.push({
                      condition_type: "if",
                      and_conditions: [
                        {
                          section_id: "",
                          section_index: null,
                          property_id: "",
                          property_index: null,
                          property_value: "",
                          comparator: "==",
                        },
                      ],
                      value: "",
                    });
                  } else {
                    queries[propertyName] = {
                      conditions: [
                        {
                          condition_type: "if",
                          and_conditions: [
                            {
                              section_id: "",
                              section_index: null,
                              property_id: "",
                              property_index: null,
                              property_value: "",
                              comparator: "==",
                            },
                          ],
                          value: "",
                        },
                      ],
                      section_id: selectedSectionProperty.section_id,
                      property_id: selectedSectionProperty.property_id,
                    };
                  }
                  // allQueries.push({
                  //   condition_type: "",
                  //   conditions: [
                  //     { section_id: "", property_id: "", property_value: "" },
                  //   ],
                  //   section_id: selectedSectionProperty.section_id,
                  //   property_id: selectedSectionProperty.property_id,
                  //   value: "",
                  // });
                  setAllQueries(_.cloneDeep(queries));
                }}
              >
                Add Condition
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={handleSectionModalClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveQuery}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        dialogClassName="modal-eidth-98w"
        show={sectionModalShow}
        onHide={handleSectionModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add/Update query for section:{" "}
            {selectedSectionProperty?.section_name} property:{" "}
            {selectedSectionProperty?.property_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSectionModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveQuery}>
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}
      <CircleLoader
        loading={isLoading}
        color="#36d7b7"
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "#36d7b7",
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export default SpecTemplateQuery;
