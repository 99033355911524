import React from "react";
import uuid from "react-uuid";
import "./orders.css";
import { size } from "lodash";
function ViewOrder({ viewOrders }: any) {
  // console.log(viewOrders);
  return (
    <>
      {viewOrders?.length > 0 && (
        <>
          <h4 className="pb-2">View Order</h4>
          {viewOrders?.length && (
            <>
              <div className="row gx-5 gy-4">
                {viewOrders.map((viewOrder: any) => (
                  <div className="col-md-6" key={uuid()}>
                    <div className="order-info">
                      <p className="m-0 fw-medium">
                        {viewOrder?.property_name}
                      </p>
                      <h5 className="m-0 ">{viewOrder?.value}</h5>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="view-order-card">
                <div className="order-header d-flex pb-3">
                  <h5 className="m-0 p-0">{viewOrders[1]?.property_name} : </h5>
                  <h5 style={{color: '#484848'}} className="paragraph-medium m-0 ps-2">{viewOrders[1]?.value}</h5>
                </div>
                <div className="d-flex gap-5">
                  {viewOrders.map((viewOrder: any, index: any) => (
                    index !== 1 && 
                    <div className="" key={uuid()} >
                      <h5 className="m-0 fw-medium">{viewOrder?.property_name}</h5>
                      <p className="m-0 paragraph-small">{viewOrder?.value}</p>
                    </div>
                  ))}
                </div>
              </div> */}
            </>
          )}

          {/* <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">Field</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              {viewOrders?.length && (
                <>
                  {viewOrders.map((viewOrder: any) => (
                    <tr key={uuid()}>
                      <td>{viewOrder?.property_name}:</td>
                      <td>{viewOrder?.value}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table> */}
        </>
      )}
    </>
  );
}

export default ViewOrder;
