import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
// import NoteDropdown from "./note-dropdown";
import "./orders.css";
import _ from "lodash";
import { useForm } from "react-hook-form";

function Selections({ orders, updatedOrders, setSelections }: any) {
  //const [specs, setSpec] = useState<any>([]);
  const { register, handleSubmit, watch, setValue } = useForm();

  let selectionSpecs: any[] = _.map(orders?.selections, (_, index) => {
    return `selection-spec-${index}`;
  });
  let selectionThreeDCads: any[] = _.map(orders?.selections, (_, index) => {
    return `selection-threeDCad-${index}`;
  });
  let selectionDrawings: any[] = _.map(orders?.selections, (_, index) => {
    return `selection-drawing-${index}`;
  });
  let selectionNotes: any[] = _.map(orders?.selections, (_, index) => {
    return `selection-note-${index}`;
  });

  let selectionRemarks: any[] = _.map(orders?.selections, (_, index) => {
    return `selection-remarks-${index}`;
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      //console.log(value);
      setSelections({ ...value });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    // let allSpecs = _.map(orders?.selections, (s) => {
    //   return { selected: true };
    // });
    // setSpec([...allSpecs]);

    _.each(orders?.selections, (_, index) => {
      setValue(`selection-note-${index}`, "");
      setValue(`selection-remarks-${index}`, "");
    });

    _.each(selectionSpecs, (spec) => {
      setValue(spec, true);
    });
    _.each(selectionThreeDCads, (spec) => {
      setValue(spec, false);
    });
    _.each(selectionDrawings, (spec) => {
      setValue(spec, false);
    });
  }, [orders]);

  useEffect(() => {
    _.each(orders?.selections, (selection: any, index: any) => {
      var opt = _.find(updatedOrders?.selections, { id: index + 1 });

      setValue(`selection-note-${index}`, opt?.note || "");
      setValue(`selection-remarks-${index}`, opt?.remarks || "");
      setValue(selectionSpecs[index], opt?.spec || selection?.spec || true);
      setValue(
        selectionThreeDCads[index],
        opt?.three_d_cad || selection?.three_d_cad || false
      );
      setValue(
        selectionDrawings[index],
        opt?.drawing || selection?.drawing || false
      );
    });
  }, [updatedOrders]);

  return (
    <>
      {orders?.selections.length > 0 && (
        <>
          <h5>Selection</h5>
          <div className="order-table table-scrollbar mt-3">
            <div className="order-table-header mb-0 py-1">
              <div className="d-flex gap-3">
                <div className="w-5">Item No.</div>
                <div className="w-11">Area</div>
                <div className="w-18">Selection</div>
                <div className="w-5">Spec</div>
                <div className="w-5">3D CAD</div>
                <div className="w-6">Drawings</div>
                <div className="w-24">Remarks</div>
                <div className="w-25">Select Note</div>
              </div>
            </div>
            <div className="order-table-body">
              {orders.selections.map((selection: any, index: any) => (
                <div className="order-table-row border" key={uuid()}>
                  <div className="d-flex flex-row gap-3 py-2">
                    <div className="w-5">{index + 1}</div>
                    <div className="w-11">{selection?.area}</div>
                    <div className="w-18">{selection?.selection || "N/A"}</div>
                    <div className="w-5">
                      <input
                        type="checkbox"
                        {...register(selectionSpecs[index])}
                      ></input>
                    </div>
                    <div className="w-5">
                      <input
                        type="checkbox"
                        {...register(selectionThreeDCads[index])}
                      ></input>
                    </div>
                    <div className="w-6">
                      <input
                        type="checkbox"
                        {...register(selectionDrawings[index])}
                      ></input>
                    </div>
                    <div className="w-24">
                      <input
                        className="form-control"
                        type="text"
                        {...register(selectionRemarks[index])}
                      ></input>
                    </div>
                    <div className="w-25">
                      <select
                        className="form-select orders-center-row-values"
                        aria-label="Default select example"
                        disabled={
                          !orders?.notes?.length || orders?.notes.length === 0
                        }
                        {...register(selectionNotes[index])}
                      >
                        <option value="">Select Note</option>
                        {orders?.notes?.length && (
                          <>
                            {orders?.notes.map((noteData: any) => (
                              <option
                                key={uuid()}
                                value={noteData?.note?.trim()}
                              >
                                {noteData?.note}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Selections;
