import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import httpClient from "../../https/httpClient";
import ViewOrder from "./view-order";
import Selections from "./selections";
import Options from "./options";
import Variations from "./variations";
import Notes from "./notes";
import Attachments from "./attachments";
import { CircleLoader } from "react-spinners";
import "./orders.css";
import _ from "lodash";
import moment from "moment";
import $ from "jquery";
import useAxiosPrivate from "../../common/useAxiosPrivate";

function Orders() {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [showUploader, setShowUploader] = useState(true);
  const [orders, setOrders] = useState<any>();
  const [backupOrders, setBackupOrders] = useState<any>();
  const [updatedOrders, setUpdatedOrders] = useState<any>([]);
  const [backUpdatedOrders, setBackupUpdatedOrders] = useState<any>([]);
  const [fileName, setFileName] = useState<any>("");
  const [orderFile, setOrderFile] = useState<any>();
  const [fileUploadEvent, setFileUploadEvent] = useState<any>(null);
  const [tabToggle, setTabToggle] = useState(1);
  const [serialNumber, setSerialNumber] = useState("");
  const [backupSerialNumber, setBackupSerialNumber] = useState("");
  //let updatedOptions: any = null;

  const handleSerialNumberChange = (event: any) => {
    setSerialNumber(event.target.value);
  };

  const searchOrderBySerialNumber = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (!!serialNumber) {
      try {
        // setUpdatedOrders([]);
        setBackupSerialNumber(serialNumber);
        setIsLoading(true);
        const response = await axiosPrivate.get(
          `orders/getOrderFromCrusaderCaravansBySerial/${serialNumber}`
        );
        const orderData = { fileName: "", orders: response.data };
        localStorage.setItem("orders", JSON.stringify(orderData));
        setOrders(response.data);
        setBackupOrders(_.cloneDeep(response.data));

        setUpdatedOrders([]);
        const updatedOrders = await getOrdersBySerialNumber(serialNumber);
        if (!!updatedOrders) {
          setUpdatedOrders(_.clone(updatedOrders));
          setBackupUpdatedOrders(_.clone(updatedOrders));
        }

        toast.success("Getting data successfully!");
        setIsLoading(false);
        setSerialNumber("");
      } catch (err: any) {
        setIsLoading(false);
        let errMessage = "Failed!";
        if (err?.response?.status === 404) {
          errMessage = "Item not found!";
        }
        console.error(err);
        toast.error(errMessage);
        setSerialNumber("");
      }
    }
  };

  const getOrdersBySerialNumber = async (serial: any) => {
    try {
      const response = await axiosPrivate.get(
        `orders/getOrderBySerial/${serial}`
      );
      return response.data;
    } catch (err: any) {
      return null;
    }
  };

  const updateTabToggle = (id: any) => {
    setTabToggle(id);
    let ord = _.cloneDeep(updatedOrders);
    let localUpdatedOrders = {
      ...ord,
      options: getOptons(),
      selections: getSelections(),
      variations: getVariations(),
    };
    setUpdatedOrders(_.cloneDeep(localUpdatedOrders));
  };

  // useEffect(() => {
  //   reset();
  // }, []);

  const reset = () => {
    // const ordersDataString = localStorage.getItem("orders");
    // if (!!ordersDataString) {
    //   setShowUploader(false);
    //   const ordersData = JSON.parse(ordersDataString);
    //   setOrders(ordersData?.orders);
    //   setFileName(ordersData?.fileName);
    // } else {
    //   deleteOrders();
    // }
    if (!!backupOrders) {
      setOrders(_.cloneDeep(backupOrders));
    }
    localStorage.setItem("updated-options", "");
    localStorage.setItem("updated-selections", "");
    localStorage.setItem("updated-variations", "");
    if (!!backUpdatedOrders) {
      setUpdatedOrders(_.cloneDeep(backUpdatedOrders));
    }
  };

  const loadFile = () => {
    $("#load-file").click();
  };

  const handleFileeUpload = async (event: any) => {
    const selectedFile = event.target.files[0];
    if (!!selectedFile) {
      setOrderFile(selectedFile);
      //event.target.value = null;
      setFileUploadEvent(event);
    }
  };

  const handleLoadFileUpload = async (event: any) => {
    const selectedFile = event.target.files[0];
    if (!!selectedFile) {
      try {
        setUpdatedOrders([]);
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", selectedFile);
        const response = await axiosPrivate.post("orders/loadCsv", formData);
        setUpdatedOrders(response?.data);
        toast.success("File loaded successfully!");
        setIsLoading(false);
        event.target.value = null;
      } catch (err: any) {
        setIsLoading(false);
        console.error(err);
        toast.error("Failed!");
      }

      // setOrderFile(selectedFile);
      // //event.target.value = null;
      // setFileUploadEvent(event);
    }
  };

  const setOptions = (options: any) => {
    //console.log(options);
    let updatedOptions = { ...options };
    localStorage.setItem("updated-options", JSON.stringify(updatedOptions));
  };

  const setSelections = (selections: any) => {
    let updatedSelections = { ...selections };
    localStorage.setItem(
      "updated-selections",
      JSON.stringify(updatedSelections)
    );
  };

  const setVariations = (variations: any) => {
    let updatedVariations = { ...variations };
    localStorage.setItem(
      "updated-variations",
      JSON.stringify(updatedVariations)
    );
  };

  const getSelections = () => {
    let allSelections = localStorage.getItem("updated-selections");
    let selections = {};
    if (!!allSelections) {
      let updatedSelections: any = JSON.parse(allSelections);
      selections = _.map(orders?.selections, (selection, index) => {
        return {
          id: index + 1,
          area: selection?.area,
          selection: selection?.selection,
          spec: updatedSelections[`selection-spec-${index}`],
          three_d_cad: updatedSelections[`selection-threeDCad-${index}`],
          drawing: updatedSelections[`selection-drawing-${index}`],
          note: updatedSelections[`selection-note-${index}`],
          remarks: updatedSelections[`selection-remarks-${index}`],
        };
      });
    } else {
      selections = _.map(orders?.selections, (selection, index) => {
        return {
          id: index + 1,
          area: selection?.area,
          selection: selection?.selection,
          spec: true,
          three_d_cad: false,
          drawing: false,
          note: "",
          remarks: "",
        };
      });
    }
    return selections;
  };
  const getVariations = () => {
    let allVariations = localStorage.getItem("updated-variations");
    let variations = {};
    if (!!allVariations) {
      let updatedVariations: any = JSON.parse(allVariations);
      variations = _.map(orders?.variations, (variation, index) => {
        return {
          id: index + 1,
          request: variation?.request,
          response: variation?.response,
          added_by: variation?.added_by,
          spec: updatedVariations[`variation-spec-${index}`],
          three_d_cad: updatedVariations[`variation-threeDCad-${index}`],
          drawing: updatedVariations[`variation-drawing-${index}`],
          note: updatedVariations[`variation-note-${index}`],
          remarks: updatedVariations[`variation-remarks-${index}`],
        };
      });
    } else {
      variations = _.map(orders?.variations, (variation, index) => {
        return {
          id: index + 1,
          request: variation?.request,
          response: variation?.response,
          added_by: variation?.added_by,
          spec: true,
          three_d_cad: false,
          drawing: false,
          note: "",
          remarks: "",
        };
      });
    }
    return variations;
  };

  const getOptons = () => {
    let options = {};
    let allOptions = localStorage.getItem("updated-options");
    if (!!allOptions) {
      let updatedOptions: any = JSON.parse(allOptions);
      options = _.map(orders?.options, (op, index) => {
        let notes = Object.keys(updatedOptions)
          .filter(function (k) {
            return k.indexOf(`option-note-${index}-`) !== -1;
          })
          .map(function (key) {
            return updatedOptions[key];
          }, []);
        return {
          id: index + 1,
          description:
            updatedOptions[`description-text-input-${index}`] ||
            updatedOptions[`description-select-input-${index}`] ||
            op.description,
          spec: updatedOptions[`spec-${index}`],
          three_d_cad: updatedOptions[`threeDCad-${index}`],
          drawing: updatedOptions[`drawing-${index}`],
          notes: notes,
          remarks: updatedOptions[`option-remarks-${index}`],
        };
      });
    } else {
      options = _.map(orders?.options, (option, index) => {
        return {
          id: index + 1,
          description: option.description,
          spec: true,
          three_d_cad: false,
          drawing: false,
          notes: [],
          remarks: "",
        };
      });
    }
    return options;
  };

  const exportData = async () => {
    try {
      setIsLoading(true);
      const requestObj = {
        view_orders: orders?.view_orders,
        options: getOptons(),
        variations: getVariations(),
        selections: getSelections(),
        notes: _.map(orders?.notes, (note, index) => {
          return { id: index + 1, ...note };
        }),
      };
      await axiosPrivate.post("orders/saveOrders", requestObj);
      // const response = await axiosPrivate.post(
      //   "orders/prepareAndExport",
      //   requestObj
      // );
      // let url = window.URL.createObjectURL(
      //   new Blob([response?.data], { type: "text/csv" })
      // );
      // const dcNo = getSepcificViewOrderItem(orders?.view_orders, "Serial");
      // let a = document.createElement("a");
      // a.href = url;
      // a.download = `DC${dcNo?.value}-submission-${moment(new Date()).format(
      //   "YYYY-MM-DD h:mm:ss"
      // )}.csv`;

      // a.click();
      // a.remove();

      const updatedOrders = await getOrdersBySerialNumber(backupSerialNumber);
      if (!!updatedOrders) {
        setUpdatedOrders(_.clone(updatedOrders));
        setBackupUpdatedOrders(_.clone(updatedOrders));
      }

      toast.success("Saved successfully!");

      setIsLoading(false);
      // reset();
    } catch (ex: any) {
      console.error(ex);
      toast.error("Failed!");
      setIsLoading(false);
    }
    //console.log(options);
  };

  const deleteOrders = async (event: any = null) => {
    event?.preventDefault();
    event?.stopPropagation();
    setOrders(null);
    setShowUploader(true);
    localStorage.setItem("orders", "");
    localStorage.setItem("noteOptions", "");
    localStorage.setItem("updated-options", "");
    localStorage.setItem("updated-selections", "");
    localStorage.setItem("updated-variations", "");
    setUpdatedOrders([]);
  };
  const getSepcificViewOrderItem = (view_orders: any, name: any) => {
    return _.first(
      _.filter(view_orders, (item) => item.property_name === name)
    );
  };
  const getViewOrders = (view_orders: any) => {
    if (!view_orders) {
      return [];
    }
    const model = getSepcificViewOrderItem(view_orders, "Model");
    const dcNo = getSepcificViewOrderItem(view_orders, "Serial");
    const dealer = getSepcificViewOrderItem(view_orders, "Dealer");
    const customer = getSepcificViewOrderItem(view_orders, "Owner");

    return [
      { property_name: "DC No", value: dcNo?.value },
      { property_name: "Model", value: model?.value },
      { property_name: "Dealer", value: dealer?.value },
      { property_name: "Customer", value: customer?.value },
    ];
  };

  const upload = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      if (!!orderFile) {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", orderFile);
        const response = await axiosPrivate.post("orders/upload", formData);
        setFileName(orderFile.name);
        const orderData = { fileName: orderFile.name, orders: response.data };
        localStorage.setItem("orders", JSON.stringify(orderData));
        setOrders(response.data);
        toast.success("File uploaded successfully!");
        setIsLoading(false);
        setShowUploader(false);
        fileUploadEvent.target.value = null;
      }
    } catch (err: any) {
      setIsLoading(false);
      console.error(err);
      toast.error("Failed!");
    }
  };

  return (
    <>
      <div className={isLoading ? "disabled" : ""}>
        {/* <h3>Order Processing System</h3> */}

        {/* {showUploader ? (
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-shrink-1">
              <div className="md-3">
                <label className="form-label">Upload File</label>
                <input
                  className="form-control"
                  type="file"
                  onChange={handleFileeUpload}
                ></input>
              </div>
            </div>
            <div className="d-flex align-items-end">
              <div>
                <button className="btn btn-success" onClick={upload}>
                  Upload
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="file-name-container d-flex align-items-center justify-content-between mb-3">
            <h3>Order Processing System</h3>
            <div className="d-flex align-items-center gap-4 px-3 py-2">
              <h5 className="mb-0">{fileName || ""}</h5>
              <button className="btn btn-danger" onClick={deleteOrders}>
                Delete
              </button>
            </div>
          </div>
        )} */}
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Serial number"
                aria-label="Serial number"
                aria-describedby="button-addon2"
                value={serialNumber}
                onChange={handleSerialNumberChange}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={searchOrderBySerialNumber}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        {orders && (
          <>
            <div className="row orders-container gx-5 gy-4 gy-lg-0">
              <div className="col-md-8">
                <ViewOrder
                  viewOrders={getViewOrders(orders?.view_orders)}
                ></ViewOrder>
              </div>
              <div className="col-md-4">
                <Notes orders={orders}></Notes>
                <Attachments orders={orders}></Attachments>
              </div>
            </div>
            <div className="mt-0">
              <div className="tab d-flex gap-2 mb-3">
                <button
                  className={`paragraph-small px-2 py-1 mb-0 ${
                    tabToggle === 1 ? "active" : ""
                  }`}
                  onClick={() => updateTabToggle(1)}
                >
                  Selection
                </button>
                <button
                  className={`paragraph-small px-2 py-1 mb-0 ${
                    tabToggle === 2 ? "active" : ""
                  }`}
                  onClick={() => updateTabToggle(2)}
                >
                  Options
                </button>
                <button
                  className={`paragraph-small px-2 py-1 mb-0 ${
                    tabToggle === 3 ? "active" : ""
                  }`}
                  onClick={() => updateTabToggle(3)}
                >
                  Variations
                </button>
              </div>
              <div style={{ minHeight: "550px" }}>
                <div className={tabToggle === 1 ? "d-block" : "d-none"}>
                  <Selections
                    orders={orders}
                    updatedOrders={updatedOrders}
                    setSelections={setSelections}
                  ></Selections>
                </div>
                <div className={tabToggle === 2 ? "d-block" : "d-none"}>
                  <Options
                    orders={orders}
                    updatedOrders={updatedOrders}
                    setOptions={setOptions}
                  ></Options>
                </div>
                <div className={tabToggle === 3 ? "d-block" : "d-none"}>
                  <Variations
                    orders={orders}
                    updatedOrders={updatedOrders}
                    setVariations={setVariations}
                  ></Variations>
                </div>
                <div className="row pt-2 pb-3">
                  <div className="col-md-12 d-flex justify-content-end p-r-10 p-t-10">
                    {/* <input
                      style={{ display: "none" }}
                      id="load-file"
                      type="file"
                      onChange={handleLoadFileUpload}
                    ></input>
                    <button
                      className="btn table-btn me-3"
                      onClick={(event: any) => {
                        event.preventDefault();
                        loadFile();
                      }}
                    >
                      Load
                    </button> */}
                    <button
                      className="btn table-btn me-3"
                      onClick={(event: any) => {
                        event.preventDefault();
                        reset();
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn table-btn"
                      onClick={(event: any) => {
                        event.preventDefault();
                        exportData();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <CircleLoader
        loading={isLoading}
        color="#36d7b7"
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "#36d7b7",
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
}

export default Orders;
