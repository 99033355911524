import { useLocation, Navigate, Outlet } from "react-router-dom";
import userStorage from "../common/userStorage";

const RequireAuth = ({ allowedUsers }: any) => {
  const authInfo = userStorage.getAuthInfo();
  const location = useLocation();

  return (!!allowedUsers && allowedUsers?.includes(authInfo?.email)) ||
    (!allowedUsers && authInfo?.accessToken) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAuth;
