import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { CircleLoader } from "react-spinners";
import "./spec-section.css";
import _ from "lodash";
import $ from "jquery";

const SpecSection = () => {
  const axiosPrivate = useAxiosPrivate();
  const [sections, setSections] = useState<any>([]);
  const [sectionName, setSectionName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sectionModalShow, setSectionModalShow] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [updatedSections, setUpdatedSections] = useState<any>([]);
  const [sectionIndex, setSectionIndex] = useState(-1);
  const [onSave, setOnSave] = useState(false);

  const handleSectionModalClose = () => setSectionModalShow(false);
  const handleSectionModalShow = () => setSectionModalShow(true);

  useEffect(() => {
    getSpecConfigurations();
  }, []);
  const getSpecConfigurations = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("settings/get_spec_sections");
      let allSections = _.cloneDeep(response.data);
      allSections = _.map(allSections, (sec) => {
        let prop = _.map(sec.properties, (p) => {
          return {
            id: p.id,
            name: p.name,
            type: p.type,
            values: p.values,
            collapse: true,
          };
        });
        sec.properties = prop;
        return sec;
      });
      setSections(_.cloneDeep(allSections));
      setUpdatedSections(_.cloneDeep(allSections));
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  const addOrUpdateSection = (event: any) => {
    // event.preventDefault();
    // event.stopPropagation();
    if (!!sectionName) {
      let allSections = _.cloneDeep(updatedSections);
      if (sectionIndex > -1) {
        allSections[sectionIndex].name = sectionName;
      } else {
        allSections.push({
          name: sectionName,
          section_id: uuid(),
          column: 1,
          row: 1,
          type: "text",
          properties: [],
        });
        setActiveTab(allSections.length - 1);
      }
      setSections([...allSections]);
      setUpdatedSections(_.cloneDeep(allSections));
      setSectionName("");
      handleSectionModalClose();
    }
    setSectionIndex(-1);
  };
  const saveSpcConfiguration = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOnSave(true);
    let invalidIndex = -1;
    for (var i = 0; i < updatedSections.length; i++) {
      if (
        !updatedSections[i].name ||
        !updatedSections[i].column ||
        !updatedSections[i].row
      ) {
        invalidIndex = i;
        break;
      }
      if (updatedSections[i].properties.length == 0) {
        invalidIndex = i;
      }
      for (var j = 0; j < updatedSections[i].properties.length; j++) {
        if (
          updatedSections[i].name !== "DELETIONS" &&
          !updatedSections[i].properties[j].name
        ) {
          invalidIndex = i;
          break;
        }
        if (updatedSections[i].properties[j].type == "dropdown") {
          if (updatedSections[i].properties[j].values.length == 0) {
            invalidIndex = i;
          }
          for (
            var k = 0;
            k < updatedSections[i].properties[j].values.length;
            k++
          ) {
            if (!updatedSections[i].properties[j].values[k].value) {
              invalidIndex = i;
              break;
            }
          }
        }
        if (invalidIndex > -1) {
          break;
        }
      }
      if (invalidIndex > -1) {
        break;
      }
    }
    if (invalidIndex > -1) {
      setActiveTab(invalidIndex);
      setSections(_.cloneDeep(updatedSections));
      toast.error("Validation failed!");
    }
    if (invalidIndex == -1) {
      try {
        let allSections = _.cloneDeep(updatedSections);
        setSections(_.cloneDeep(allSections));
        setUpdatedSections(_.cloneDeep(allSections));
        setIsLoading(true);

        let allUpdatedSections = _.cloneDeep(updatedSections);
        allUpdatedSections = _.map(allUpdatedSections, (sec) => {
          let prop = _.map(sec.properties, (p) => {
            return {
              id: p.id,
              name: p.name,
              type: p.type,
              values: p.values,
            };
          });
          sec.properties = prop;
          return sec;
        });

        const response = await axiosPrivate.post(
          "settings/save_spec_sections",
          { spec_sections: allUpdatedSections }
        );
        setIsLoading(false);
        toast.success("Successfully saved!");
        setOnSave(false);
        // await getSpecConfigurations();
        // setActiveTab(0);
      } catch (e) {
        setIsLoading(false);
        setOnSave(false);
        toast.error("Failed!");
      }
    }
    invalidIndex = -1;
  };

  return (
    <>
      <div className={`mt-0 ${isLoading ? "disabled" : ""}`}>
        <button
          className="m-r-10 btn btn-primary"
          onClick={handleSectionModalShow}
        >
          Add New Section
        </button>
        <button className="btn btn-success" onClick={saveSpcConfiguration}>
          Save
        </button>
        <ul className="nav nav-tabs m-t-10" id="myTab" role="tablist">
          {sections.map((section: any, index: number) => (
            <li className="nav-item" role="presentation" key={uuid()}>
              <button
                onClick={() => {
                  setActiveTab(index);
                  setSections(_.cloneDeep(updatedSections));
                }}
                className={`nav-link ${activeTab == index ? "active" : ""}`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target={`#section-${index}`}
                type="button"
                role="tab"
                aria-controls={`section-${index}`}
                aria-selected="true"
              >
                <span>{section.name}</span>
                <i
                  className="fa fa-edit m-l-10"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSectionIndex(index);
                    setSectionName(section.name);
                    handleSectionModalShow();
                  }}
                ></i>
                <i
                  className="fa fa-trash m-l-10"
                  aria-hidden="true"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    let tabSelection = activeTab - 1;
                    if (tabSelection < 0) {
                      tabSelection = 0;
                    }
                    setActiveTab(tabSelection);
                    let allSections = _.cloneDeep(updatedSections);
                    allSections.splice(index, 1);
                    setSections(_.cloneDeep(allSections));
                    setUpdatedSections(_.cloneDeep(allSections));
                  }}
                ></i>
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="myTabContent">
          {sections.map((section: any, index: number) => (
            <div
              key={uuid()}
              className={`tab-pane fade ${
                activeTab == index ? "show active" : ""
              }`}
              id={`section-${index}`}
              role="tabpanel"
              aria-labelledby={`section-${index}`}
            >
              <div className="row m-t-5">
                <div className="col-2">
                  <label className="form-label">Column</label>
                  <input
                    type="number"
                    className={`form-control ${
                      onSave && !section.column ? "error-field" : ""
                    }`}
                    defaultValue={section.column}
                    onChange={(e) => {
                      let val = e.target.value;
                      updatedSections[index].column = !!val
                        ? parseInt(val)
                        : null;
                      setUpdatedSections(updatedSections);
                    }}
                  />
                </div>
                <div className="col-2">
                  <label className="form-label">Row</label>
                  <input
                    type="number"
                    className={`form-control ${
                      onSave && !section.row ? "error-field" : ""
                    }`}
                    defaultValue={section.row}
                    onChange={(e) => {
                      let val = e.target.value;
                      updatedSections[index].row = !!val ? parseInt(val) : null;
                      setUpdatedSections(updatedSections);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">Section Property Name</div>
                <div className="col-8">Section Property Input Type</div>
              </div>
              <div className="property-container">
                {section.properties &&
                  section.properties.map((property: any, ind: number) => (
                    <div key={uuid()} className="m-b-5">
                      <div className="row m-b-5">
                        <div className="col-4">
                          <input
                            type="text"
                            className={`form-control ${
                              onSave &&
                              section.name !== "DELETIONS" &&
                              !property.name
                                ? "error-field"
                                : ""
                            }`}
                            defaultValue={property.name}
                            onBlur={(e) => {
                              updatedSections[index].properties[ind].name =
                                e.target.value;
                              setUpdatedSections(updatedSections);
                            }}
                          />
                        </div>
                        <div className="col-8 d-flex">
                          <select
                            className="form-select"
                            defaultValue={property.type || "text"}
                            onChange={(e) => {
                              let value = e.target.value;
                              let allSections = _.cloneDeep(updatedSections);
                              allSections[index].properties[ind].type = value;
                              allSections[index].properties[ind].values =
                                value === "dropdown"
                                  ? [{ id: uuid(), value: "" }]
                                  : null;
                              setSections(_.cloneDeep(allSections));
                              setUpdatedSections(_.cloneDeep(allSections));
                            }}
                          >
                            <option value="text">text</option>
                            <option value="dropdown">dropdown</option>
                          </select>
                          <button
                            className="btn btn-sm"
                            onClick={() => {
                              let allSections = _.cloneDeep(updatedSections);
                              allSections[index].properties.splice(ind, 1);
                              setSections(_.cloneDeep(allSections));
                              setUpdatedSections(_.cloneDeep(allSections));
                            }}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      {property.values && property.values.length && (
                        <>
                          <div className="row m-b-5">
                            <div className="col-4"></div>
                            <div className="col-8 d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <strong>Dropdown Values</strong>
                              </div>
                              <button
                                className="btn btn-sm"
                                onClick={() => {
                                  let allSections =
                                    _.cloneDeep(updatedSections);
                                  allSections[index].properties[ind].collapse =
                                    !allSections[index].properties[ind]
                                      .collapse;
                                  setSections(_.cloneDeep(allSections));
                                  setUpdatedSections(_.cloneDeep(allSections));
                                }}
                              >
                                <i
                                  className={`prop-collapse-icon fa ${
                                    property.collapse
                                      ? "fa-angle-down"
                                      : "fa-angle-up"
                                  }`}
                                ></i>
                              </button>
                            </div>
                          </div>
                          <div
                            className={
                              property.collapse
                                ? "prop-collapse"
                                : "prop-expand"
                            }
                          >
                            {property.values.map((val: any, vInd: number) => {
                              return (
                                <div className="row m-b-5" key={uuid()}>
                                  <div className="col-4"></div>
                                  <div className="col-8 d-flex">
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        onSave && !val.value
                                          ? "error-field"
                                          : ""
                                      }`}
                                      defaultValue={val.value}
                                      onBlur={(e) => {
                                        updatedSections[index].properties[
                                          ind
                                        ].values[vInd].value = e.target.value;
                                        setUpdatedSections(updatedSections);
                                      }}
                                    />
                                    <button
                                      className="btn btn-sm"
                                      onClick={() => {
                                        let allSections =
                                          _.cloneDeep(updatedSections);
                                        allSections[index].properties[
                                          ind
                                        ].values.splice(vInd, 1);
                                        if (
                                          allSections[index].properties[ind]
                                            .values.length == 0
                                        ) {
                                          allSections[index].properties[
                                            ind
                                          ].values = [
                                            { id: uuid(), value: "" },
                                          ];
                                        }
                                        setSections(_.cloneDeep(allSections));
                                        setUpdatedSections(
                                          _.cloneDeep(allSections)
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="d-flex w-100 justify-content-end">
                              <button
                                className="btn btn-sm btn-success"
                                onClick={(event) => {
                                  let allSections =
                                    _.cloneDeep(updatedSections);
                                  allSections[index].properties[
                                    ind
                                  ].values.push({
                                    id: uuid(),
                                    value: "",
                                  });
                                  setSections(_.cloneDeep(allSections));
                                  setUpdatedSections(_.cloneDeep(allSections));
                                }}
                              >
                                Add Value
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </div>
              <div className="d-flex w-100 justify-content-end p-b-10">
                <button
                  className="btn btn-sm btn-success"
                  onClick={(event) => {
                    let allSections = _.cloneDeep(updatedSections);
                    allSections[index].properties.push({
                      id: uuid(),
                      name: "",
                      type: "text",
                    });
                    setSections(_.cloneDeep(allSections));
                    setUpdatedSections(_.cloneDeep(allSections));
                  }}
                >
                  Add Property
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <Modal show={sectionModalShow} onHide={handleSectionModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Section Name</label>
            <input
              type="text"
              className="form-control"
              id="section-name"
              value={sectionName}
              onChange={(e) => setSectionName(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSectionModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addOrUpdateSection}>
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}
      <div
        style={{ display: sectionModalShow ? "block" : "none" }}
        className="modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Section
                  </h5>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleSectionModalClose}
                  ></button>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">Section Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="section-name"
                  value={sectionName}
                  onChange={(e) => setSectionName(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={handleSectionModalClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addOrUpdateSection}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <CircleLoader
        loading={isLoading}
        color="#36d7b7"
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "#36d7b7",
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export default SpecSection;
