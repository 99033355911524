import React from "react";
import uuid from "react-uuid";
import "./orders.css";

function Notes({ orders }: any) {
  return (
    <>
      {orders?.notes.length > 0 && (
        <div className="note">
          <h4 className="pb-2">Notes</h4>
          <div className="note-list order-info bg-white scrollbar">
            <ul>
              {orders.notes.map((note: any, index: number) => (
                <li key={uuid()} className="paragraph-small">
                  <span className="fw-medium pe-2">{index + 1}.</span>{" "}
                  {note?.note}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Notes;
